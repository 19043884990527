import React from 'react'
import './about.css'
import ME from '../../assets/me-about.jpg'
import {FaAward} from 'react-icons/fa'
import {FiUsers} from 'react-icons/fi'
import {VscFolderLibrary} from 'react-icons/vsc'

const About = () => {
  return (
    <section id='about'> 
    <h5> Get To Know </h5>
    <h2> About Me </h2>

    <div className='container about__container'>
      <div className="about__me"></div>
        <div className="about__me-image">
          <img src={ME} alt=''/>
        

      <div className="about__content"></div>   
        <div className="about__cards">
          <article className='about__card'>
            <FaAward className='about__icon'/>
            <h5> Experience </h5>
            <small>3+ Years Working </small>
          </article>

          <article className='about__card'>
            <FiUsers className='about__icon'/>
            <h5> Clients </h5>
            <small>200+ WorldWide </small>
          </article>

          <article className='about__card'>
            <VscFolderLibrary className='about__icon'/>
            <h5> Projects </h5>
            <small>80+ Completed </small>
          </article>

          </div>
            <p>
              Java is the  programming language and development platform.
              It reduces costs, shortens development timeframes, drives innovation, and improves application services.
              With millions of developers running more than 51 billion Java Virtual Machines worldwide, 
              Java continues to be the development platform of choice for enterprises and developers.
            </p>

            <a href="#contact" className='btn btn-primary'> Let's Talk </a>
        </div>
      </div>
    </section>
  )
}

export default About
